/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, Col, Dropdown, Form, Icon, Input, Menu, message, Radio, Row, Table, Tooltip, Upload } from 'antd'
import { Link } from 'react-router-dom'
import { Space } from '../../common-components/Space'
import ExpandListWo from './subTableListWo/ExpandListWo'
import { StoreState } from '../../store'
import { connect, useDispatch } from 'react-redux'
import { downloadTempleteWorkorder, getAllWorkOrders, getAllWorkOrdersWithPagination, importDataWorkorder } from './service'
import { decryptDataVspace } from '../../common-misc'
import { FormComponentProps } from 'antd/lib/form'
import { UpdateWorkOrderPageEvent } from "./state-event";
import { myFilter } from "./model";
import { read, utils, } from 'xlsx'

const mapStateToProps = (state: StoreState) => {
    return {
        workOrderList: state.workOrderList,
        totalWorkOrderContent: state.totalWorkOrderContent,
        workOrdersPagination: state.workOrdersPagination
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {

    getAllWorkOrdersWithPagination: (page: number, pageSize: number, role?: string, search?: string, filterStatus?: string,
        fieldName?: string, orderBy?: string, searchFieldValue?: myFilter[]) => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps

const { Search } = Input
const serviceCenter = process.env.REACT_APP_BASE_URL_ROLE_SERVICE_CENTER!!
const serviceAPPROVAL = process.env.REACT_APP_BASE_URL_ROLE_APPROVAL!!
const leadServiceCenter = process.env.REACT_APP_BASE_URL_ROLE_LEAD_SERVICE_CENTER!!
const WoList: React.FC<Props> = (props: Props) => {
    const dataVspace = decryptDataVspace()
    let email: string = ''
    let role: string = ''
    if (typeof dataVspace === 'object') {
        email = dataVspace.email
        role = dataVspace.roleId.toString()
    }

    const [searchText, setSearchText] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const [vSpaceName, setVspaceName] = useState<string>('')
    const [pageSize, setPageSize] = useState<number>(10)
    const [totalWO, setTotalWO] = useState<number>(props.workOrdersPagination.total || 1)
    const [isChangeStatus, setIsChangeStatus] = useState<boolean>(false)
    const [vSpaceRole, setVspaceRole] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const status = ['All Open Status', 'New', 'Assigned', 'InProgress', 'Completed', 'Closed', 'Cancelled']
    const statusForApprove = ['Awaiting Approval', 'Approved / Rejected']
    const [filterStatus, setFilterStatus] = useState<string | undefined>(Number(role) !== 799 ? 'All Open Status' : 'Awaiting Approval')
    const { getFieldDecorator, resetFields } = props.form
    const dispatch = useDispatch()
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [sortBy, setSortBy] = useState<string>('createdDate')
    const [searchFieldValue, setSearchFieldValue] = useState<myFilter[]>()

    useEffect(() => {
        setVspaceRole(Number(role))
        setVspaceName(email)
        search(null, filterStatus)
    }, [])

    useEffect(() => {
        setPage(1)
        setTotalWO(props.workOrdersPagination.total || 1)
    }, [props.workOrdersPagination.total])

    useEffect(() => {
        search(null, filterStatus)
        setIsChangeStatus(false)
    }, [isChangeStatus])

    useEffect(() => {
        setIsLoading(true)
        props.getAllWorkOrdersWithPagination(page, pageSize, role, searchText, filterStatus, sortBy, orderBy, searchFieldValue).then((res) => {
            if (res) {
                setIsLoading(false)
            }
        }).catch((err) => {
            setIsLoading(false)
            message.error(`Failed fetching data. ${err}`)
        })
    }, [page, sortBy, orderBy, searchFieldValue])

    const search = (value, filter) => {
        setIsLoading(true)
        props.getAllWorkOrdersWithPagination(page, pageSize, role, value, filter, sortBy, orderBy, searchFieldValue).then((res) => {
            if (res) {
                setIsLoading(false)
            }
        }).catch((err) => {
            setIsLoading(false)
            message.error(`Failed fetching data. ${err}`)
        })
    }

    const checkTextPlaceholder = (value: string): string => {
        let tempValue: string = value
        if (tempValue === 'number') {
            tempValue = 'Order ID'
        } else if (tempValue === 'topic') {
            tempValue = 'Topic'
        } else if (tempValue === 'contactUserName') {
            tempValue = 'Contact User'
        } else if (tempValue === 'status') {
            tempValue = 'Status'
        } else if (tempValue === 'contactUserLocation') {
            tempValue = 'Location'
        } else if (tempValue === 'supportTeam') {
            tempValue = 'Assignment group'
        } else if (tempValue === 'assignee') {
            tempValue = 'Assigned to'
        } else if (tempValue === 'lastModifiedDate') {
            tempValue = 'Updated date'
        } else if (tempValue === 'closedJobs') {
            tempValue = 'Job'
        } else if (tempValue === 'type') {
            tempValue = 'Type'
        } else if (tempValue === 'createdDate') {
            tempValue = 'Created Date'
        } else if (tempValue === 'jobTopic') {
            tempValue = 'Job Topic'
        }
        return tempValue
    }

    const getColumnSearchProps = (dataIndex: any) => ({
        // eslint-disable-next-line react/display-name
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any): any => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${checkTextPlaceholder(dataIndex)}`}
                    value={selectedKeys[0]}
                    onChange={(e: any) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8, color: 'red' }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        onFilter: (value: any, record: any) =>
            record.workOrder[dataIndex] ? record.workOrder[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        // eslint-disable-next-line react/display-name
        filterIcon: (filtered: any) => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
            }
        }
    })

    const handleSearch = (selectedKeys: any, confirm: any) => {
        confirm()
        // setSearchText(selectedKeys[0])
    }

    const handleReset = (clearFilters: any) => {
        clearFilters()
        // setSearchText('')
    }

    const getProperty = (obj, key) => {
        return obj[key]
    }

    const sorter = (a: string, b: string) => (a !== null && b !== null ? (a as string || '').localeCompare(b as string || '') : (a as string || '').localeCompare(b as string || ''))
    const convertFieldName = (key) => {
        return key.split('.')[1]
    }
    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        if (sorter.field) {
            if (sorter.order === 'ascend') {
                setOrderBy('ASC')
                if (sorter.field) {
                    setSortBy(convertFieldName(sorter.field))
                } else {
                    setSortBy('createdDate')
                }
            } else if (sorter.order === 'descend') {
                setOrderBy('DESC')
                if (sorter.field) {
                    setSortBy(convertFieldName(sorter.field))
                } else {
                    setSortBy('createdDate')
                }
            } else {
                setSortBy('createdDate')
            }
        }
        if (filters) {
            const listNewObj: myFilter[] = []
            for (const key of Object.keys(filters)) {
                const newObject: myFilter = {
                    key: '',
                    value: undefined
                }
                if (getProperty(filters, key)[0]) {
                    newObject.key = convertFieldName(key)
                    newObject.value = getProperty(filters, key)[0]
                    listNewObj.push(newObject)
                }
            }
            console.log(listNewObj)
            setSearchFieldValue(listNewObj)
        }

        // setTotalWO(pagination.total || 0)
        setPageSize(pagination.pageSize)
        setPage(pagination.current)
    }

    const handleSearchWO = (value) => {
        setSearchText(value)
        resetFields([
            'Filter'
        ])
        if (value) {
            setFilterStatus(undefined)
            search(value, undefined)
        } else {
            search(value, Number(role) !== 799 ? 'All Open Status' : 'Awaiting Approval')
            setFilterStatus(Number(role) !== 799 ? 'All Open Status' : 'Awaiting Approval')
        }
    }

    const columns = [
        {
            title: 'Order ID',
            dataIndex: 'workOrder.number',
            key: 'workOrder.number',
            sorter: (a, b) => sorter(a.workOrder.number, b.workOrder.number),
            ...getColumnSearchProps('number'),
            render: (record) => {
                const incNo = props.workOrderList.find(res => res.workOrder?.number === record)
                return (
                    <div style={{ margin: 'unset' }}>{<Link to={`/WoFormEdit/${incNo?.workOrder?.id}/WO`}>{record}</Link>}</div>
                )
            }
        },
        {
            title: 'INC/WO',
            dataIndex: 'workOrder.referRemedyHd',
            key: 'workOrder.referRemedyHd',
            sorter: (a, b) => sorter(a.workOrder.referRemedyHd, b.workOrder.referRemedyHd),
            ...getColumnSearchProps('referRemedyHd')
        },
        {
            title: 'Topic',
            dataIndex: 'workOrder.topic',
            key: 'workOrder.topic',
            sorter: (a, b) => sorter(a.workOrder.topic, b.workOrder.topic),
            ...getColumnSearchProps('topic'),
            render: (record) => {
                return (
                    <>
                        {
                            <Tooltip placement="top" title={record}>
                                <p style={{ margin: 'unset' }}>{record.length > 10 ? (<>{record.substring(0, 10) + '...'}</>) : (<>{record}</>)}</p>
                            </Tooltip>
                        }
                    </>
                )
            }
        },
        {
            title: 'Contact User',
            dataIndex: 'workOrder.contactUserName',
            key: 'workOrder.contactUserName',
            sorter: (a, b) => sorter(a.workOrder.contactUserName, b.workOrder.contactUserName),
            ...getColumnSearchProps('contactUserName'),
            render: (record) => {
                return (
                    <>
                        {
                            <Tooltip placement="top" title={record}>
                                <p style={{ margin: 'unset' }}>{record.length > 10 ? (<>{record.substring(0, 10) + '...'}</>) : (<>{record}</>)}</p>
                            </Tooltip>
                        }
                    </>
                )
            }
        },
        {
            title: 'Location',
            dataIndex: 'workOrder.contactUserLocation',
            key: 'workOrder.contactUserLocation',
            sorter: (a, b) => sorter(a.workOrder.contactUserLocation, b.workOrder.contactUserLocation),
            ...getColumnSearchProps('contactUserLocation'),
            render: (record) => {
                return (
                    <>
                        {
                            <Tooltip placement="top" title={record}>
                                <p style={{ margin: 'unset' }}>{record.length > 15 ? (<>{record.substring(0, 15) + '...'}</>) : (<>{record}</>)}</p>
                            </Tooltip>
                        }
                    </>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'workOrder.status',
            key: 'workOrder.status',
            sorter: (a, b) => sorter(a.workOrder.status, b.workOrder.status),
            ...getColumnSearchProps('status')
        },
        {
            title: 'Assignment group',
            dataIndex: 'workOrder.supportTeam',
            key: 'workOrder.supportTeam',
            sorter: (a, b) => sorter(a.workOrder.supportTeam, b.workOrder.supportTeam),
            ...getColumnSearchProps('supportTeam'),
            render: (record) => {
                return (
                    <>
                        {
                            <Tooltip placement="top" title={record}>
                                <p style={{ margin: 'unset' }}>{record?.length > 12 ? (<>{record.substring(0, 12) + '...'}</>) : (<>{record}</>)}</p>
                            </Tooltip>
                        }
                    </>
                )
            }
        },
        {
            title: 'Assigned to',
            dataIndex: 'workOrder.assignee',
            key: 'workOrder.assignee',
            sorter: (a, b) => sorter(a.workOrder.assignee, b.workOrder.assignee),
            ...getColumnSearchProps('assignee'),
            render: (record) => {
                return (
                    <>
                        {
                            <Tooltip placement="top" title={record}>
                                <p style={{ margin: 'unset' }}>{record?.length > 12 ? (<>{record.substring(0, 12) + '...'}</>) : (<>{record}</>)}</p>
                            </Tooltip>
                        }
                    </>
                )
            }
        },
        // {
        //     title: 'Priority',
        //     dataIndex: 'workOrder.priority',
        //     key: 'workOrder.priority',
        //     sorter: (a, b) => sorter(a.workOrder.priority, b.workOrder.priority),
        //     ...getColumnSearchProps('priority')
        // },
        {
            title: 'Type',
            dataIndex: 'workOrder.type',
            key: 'workOrder.type',
            sorter: (a, b) => sorter(a.workOrder.type, b.workOrder.type),
            ...getColumnSearchProps('type')
        },
        {
            title: 'Updated date',
            dataIndex: 'workOrder.lastModifiedDate',
            key: 'workOrder.lastModifiedDate',
            sorter: (a, b) => sorter(a.workOrder.lastModifiedDate, b.workOrder.lastModifiedDate),
            ...getColumnSearchProps('lastModifiedDate')
        },
        {
            title: 'Created date',
            dataIndex: 'workOrder.createdDate',
            key: 'workOrder.createdDate',
            sorter: (a, b) => sorter(a.workOrder.createdDate, b.workOrder.createdDate),
            ...getColumnSearchProps('createdDate')
        },
        {
            title: 'Job Topic',
            dataIndex: 'workOrder.jobTopic',
            // key: 'related',
            // sorter: (a, b) => sorter(a.workOrder.jobTopic, b.workOrder.jobTopic),
            ...getColumnSearchProps('jobTopic'),
            render: (record) => {
                return (
                    <>
                        {
                            <Tooltip placement="top" title={record}>
                                <p style={{ margin: 'unset' }}>{record.length > 10 ? (<>{record.substring(0, 10) + '...'}</>) : (<>{record}</>)}</p>
                            </Tooltip>
                        }
                    </>
                )
            }
        },
        {
            title: 'Job Status',
            dataIndex: 'workOrder.closedJobs',
            key: 'workOrder.closedJobs',
            // sorter: (a, b) => sorter(a.workOrder.closedJobs, b.workOrder.closedJobs),
            ...getColumnSearchProps('closedJobs')
        }
    ]

    const handleIsChangeStatus = (isChange) => {
        setIsChangeStatus(isChange)
    }

    const expandedRowRender = (expandedRow) => {
        const checkPermissionsUser = (): boolean => {
            let check = false
            if (vSpaceRole !== parseInt(serviceCenter) && vSpaceRole !== parseInt(leadServiceCenter)) {
                if (vSpaceRole !== parseInt(serviceAPPROVAL)) {
                    if (expandedRow.workOrder?.assigneeEmail || expandedRow.workOrder?.engineerEmail) {
                        if (expandedRow.workOrder?.assigneeEmail !== vSpaceName) {
                            check = true
                        }
                        if (expandedRow.workOrder?.engineerEmail === vSpaceName) {
                            check = false
                        }
                    } else {
                        if (vSpaceRole === parseInt(serviceCenter)) {
                            check = false
                        }
                        if (expandedRow.workOrder?.assigneeRoleId !== vSpaceRole || expandedRow.workOrder?.engineerRoleId !== vSpaceRole) {
                            check = true
                        }
                    }
                }
            }
            return check
        }
        return <ExpandListWo woId={expandedRow.workOrder.id}
            username={vSpaceName}
            handleIsChangeStatus={handleIsChangeStatus}
            permission={checkPermissionsUser()}
            roleId={vSpaceRole}
            isCloseAndCancel={expandedRow.workOrder?.status === 'Closed' || expandedRow.workOrder?.status === 'Cancelled'} />
    }

    const handleFilterStatus = (e) => {
        setFilterStatus(e.target.value)
        setIsLoading(true)
        // props.getAllWorkOrders(role, '', e.target.value).then((res) => {
        //     if (res) {
        //         setIsLoading(false)
        //     }
        // }).catch((err) => {
        //     setIsLoading(false)
        //     message.error(`Failed fetching data. ${err}`)
        // })
        props.getAllWorkOrdersWithPagination(page, pageSize, role, '', e.target.value, sortBy, orderBy).then((res) => {
            if (res) {
                setIsLoading(false)
            }
        }).catch((err) => {
            setIsLoading(false)
            message.error(`Failed fetching data. ${err}`)
        })
    }

    const filterGroup = (dataSource: string[]) => {
        return (
            <Row style={{ marginTop: 20 }}>
                {getFieldDecorator('Filter', {
                    initialValue: filterStatus
                })(
                    <Radio.Group onChange={(value) => {
                        handleFilterStatus(value)
                    }}>
                        {dataSource.map((item) => {
                            return (
                                <Radio.Button
                                    key={item}
                                    value={item}
                                >{item}</Radio.Button>
                            )
                        })}
                    </Radio.Group>
                )}
            </Row>
        )
    }
    const handleOnClickPageNumber = (page: number) => {
        dispatch(UpdateWorkOrderPageEvent.build(page))
        setPage(page)
    }

    const uploadProps = {
        multiple: false,
        onRemove: (file: any) => {

        },
        beforeUpload: (file: any) => {
            console.log('type : ', file.type)
            const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'text/csv'
            const isTextFile = file.type === 'text/plain'
            if (!isXlsx && !isTextFile) {
                message.error('File type is not xlsx or text file.')
                return false
            } else {
                const reader = new FileReader()
                reader.readAsBinaryString(file)
                reader.onloadend = function (e: any) {
                    const bstr = e.target.result
                    const wb = read(bstr, { type: 'binary' })
                    const wsName = wb.SheetNames[0]
                    const ws = wb.Sheets[wsName]
                    const data: any[] = utils.sheet_to_json(ws)
                    // if (!data[0]?.hasOwnProperty('Category') && !data[0]?.hasOwnProperty('Subcategory') && !data[0]?.hasOwnProperty('PM Items')) {
                    //     Failed('Failed to save, you upload file incorrect format.')
                    //     return
                    // }
                    try {
                        console.log(data);
                        importDataWorkorder(data).then(() => {
                            message.success('import data successfully')
                        }).catch((err) => {
                            message.error('failed to imported data')
                        }).finally(() => {
                            props.getAllWorkOrdersWithPagination(page, pageSize, role, searchText, filterStatus, sortBy, orderBy, searchFieldValue).then((res) => {
                                if (res) {
                                    setIsLoading(false)
                                }
                            }).catch((err) => {
                                setIsLoading(false)
                                message.error(`Failed fetching data. ${err}`)
                            })
                        })
                    } catch (error) {
                        message.error('Failed to save. You upload file incorrect format!!')
                    }
                }
                return false
            }
        },
        showUploadList: false
    }

    const handleDownloadTemplate = () => {
        downloadTempleteWorkorder().then(() => {
            message.success('download template successfully.')
        }).catch((err) => {
            message.error('download template failed')
        })
    }

    const menu = (
        <Menu >

            <Menu.Item key="1"><Icon type="upload" />
                <Upload
                    {...uploadProps}
                    listType="text"
                    className="upload-list-inline"
                    showUploadList={false}
                    multiple={false}
                >
                    <p style={{color: '#323276'}}>Import Work Orders</p>
                </Upload>

            </Menu.Item>
            <Menu.Item key="2" onClick={handleDownloadTemplate}><Icon type="file-text" /> Template Import</Menu.Item>
        </Menu>
    );

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>Work Order</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={20} />
            <Card style={{ overflow: 'auto' }}>
                {/* <div> */}
                {/*    <h3 className='main-title'>Work Order</h3> */}
                {/* </div> */}
                <Row gutter={16}>
                    <Col span={12}>
                        <Search
                            placeholder="Search"
                            onSearch={handleSearchWO}
                            allowClear
                            style={{ width: 180 }}
                        />
                    </Col>
                    {vSpaceRole !== 799
                        ? <Col span={12} style={{ textAlign: 'end' }}>
                            <Dropdown overlay={menu} >
                                <Button type="default" style={{ margin: 5, marginRight: 16 }}>
                                    <Icon type="upload" /> Import <Icon type="down" />
                                </Button>
                            </Dropdown>
                            <Link to={'/WoForm'} ><Button type="primary">Add New</Button></Link>
                        </Col>
                        : null
                    }
                </Row>
                {
                    Number(role) !== parseInt(serviceAPPROVAL)
                        ? filterGroup(status)
                        : filterGroup(statusForApprove)
                }
                <br />
                <div style={{ overflow: 'auto' }}>
                    <Table
                        rowKey={record => record.workOrder?.id as string}
                        columns={columns}
                        dataSource={props.workOrderList}
                        expandedRowRender={expandedRowRender}
                        onChange={handleTableChange}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: totalWO,
                            showSizeChanger: true,
                            onChange: (event) => { handleOnClickPageNumber(event) }
                        }}
                        loading={isLoading}
                    />
                </div>
            </Card>
        </>
    )
}

const WoListPage = Form.create<Props>({ name: 'WoList' })(WoList)

export default connect(mapStateToProps, {
    getAllWorkOrders, getAllWorkOrdersWithPagination
})(WoListPage)
